<template>
  <div>
    <template v-if="signedIn">
      <slot name="authenticated" />
    </template>
    <template v-else>
      <slot name="anonymous" />
    </template>
  </div>
</template>
<script>
  export default {
    props: {
      onSigned: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        signedIn: false
      }
    },
    mounted() {
      this.$mgr.getAcessToken().then(
        (acessToken) => {
          if (acessToken) {
            this.$axios.defaults.headers.common.Authorization = 'Bearer ' + acessToken
            this.signedIn = true
            this.onSigned()
          }
        },
        (err) => {
        }
      )
    },
    methods: {
    }
  }
</script>
<style scoped>
</style>
