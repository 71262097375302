<template>
  <div>
    <div v-if="showWriteComment" class="backGroud" @click="hideWrite"></div>
    <div v-if="showWriteComment" class="footBar">
      <div class="bar">
        <div style="flex:auto">
          <AuthenticatedView :on-signed="getUser">
            <template v-slot:authenticated>
              <div class="text-left writeCommentInfo">
                <div style="font-size:12px;font-weight:bold">提示：</div>
                <div style="margin-left:5px">
                  请注意遵守我国相关法律规定对留言内容自行负责<br />
                  为了安全本站保留屏蔽不良言论的权力
                </div>
              </div>
              <div style="display:flex;padding:10px 0 5px;background-color: #fff;padding:10px;">
                <!--<textarea style="flex:auto;resize:none" v-model="text" />-->
                <div style="flex:auto">
                  <AutoTextarea v-model="text" :maxHeight="70"></AutoTextarea>
                </div>
                <div style="flex:none">
                  <button title="发送" @click="send" :disabled="sending" style="font-size: 20px; background-color: transparent; border: none; color: #999;"><i class="fas fa-arrow-circle-up"></i></button>
                </div>
              </div>
            </template>
            <template v-slot:anonymous>
              <div class="writeCommentInfo">
                <div class="text-left">
                  <div style="font-size:12px;font-weight:bold">提示：</div>
                  <div style="margin-left:5px">
                    留言需要在微信浏览中进行<br />
                    留言会调取微信用户资料进行身份验证<br />
                    请注意遵守我国相关法律规定对留言内容自行负责<br />
                    为了安全本站保留屏蔽不良言论的权力
                  </div>
                </div>
                <div style="padding:0 10px">
                  请先 <button @click="signIn()">登录</button>
                </div>
              </div>
            </template>
          </AuthenticatedView>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AuthenticatedView from '../../Auth/AuthenticatedView.vue'
  import AutoTextarea from '../../AutoTextarea.vue'
  export default {
    components: {
      AutoTextarea,
      AuthenticatedView
    },
    props: {
    },
    data() {
      return {
        showWriteComment: false,
        sending: false
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      guid() {
        return this.publicData.guid
      },
    },
    watch: {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          showWrite: this.showWrite,
          hideWrite: this.hideWrite,
        }
      })
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      signIn() {
        this.$mgr.signIn()
      },
      showWrite(reply) {
        this.showWriteComment = true
        if (this.reply != (reply || null)) {
          this.text = ''
        }
        this.reply = reply || null
      },
      hideWrite() {
        this.showWriteComment = false
      },
      send() {
        this.writeComment(this.text, this.reply).then(() => {
          this.hideWrite()
          this.text = ''
        })
      },
      writeComment(comment, reply) {
        this.sending = true
        if (comment != null && comment != '') {
          var data = {
            text: comment
          }
          if (reply) {
            data.parentGUID = reply.guid
            if (reply.rootGUID) {
              data.rootGUID = reply.rootGUID
            } else {
              data.rootGUID = reply.guid
            }
          }
          return this.$axios.post(`/Api/Comments/${this.guid}`, data).catch((err) => {
            switch (err.response.status) {
              case 401:
                alert('需要登录')
                break
              default:
            }
            this.sending = false
          }).then((res) => {
            this.getFunc({ target: 'comment', name: 'InsertComment' })(res, reply)
            this.sending = false
          })
        }
      },
    },
  }
</script>
<style scoped>
  .backGroud {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.1;
  }

  .writeCommentInfo {
    font-size: 10px;
    color: #666;
    padding: 10px;
    background-color: #fffc;
    box-shadow: #0008 0 0 10px;
  }

  .footBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center
  }

    .footBar .bar {
      display: flex;
      max-width: 100%;
      width: 640px;
      /*padding: 5px;*/
      justify-content: space-between;
      white-space: nowrap;
      flex-wrap: nowrap;
      /*background-color: #fff8;*/
      /*box-shadow: #0008 0 0 10px;*/
    }
</style>
